import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import NewLayoutEn from '../../../components/newsLayoutEn.js';
export const _frontmatter = {
  "title": "18 years of slovensko.sk",
  "date": "2021-31-05",
  "categories": "en",
  "description": "Central portal of public administration was for the first time publicly presented 18 years ago, 23.4.2003 during the no longer existing COFAX exhibition. It was made available on the well-known address obcan.sk and was perceived as one of the milestones in the construction of eGovernment in Slovakia. Its popularity at the time among the people was comparable to those of commercial servers.",
  "key": "en",
  "preview": "Central portal of public administration was for the first time publicly presented 18 years ago, 23.4.2003 during the no longer existing COFAX exhibition. It was made available on the well-known address obcan.sk and was perceived as one of the milestones in the construction..."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = NewLayoutEn;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>18 years of slovensko.sk</h1>
    <p>
    Central portal of public administration was for the first time publicly presented 18 years ago, 23.4.2003 during the
    no longer existing COFAX exhibition. It was made available on the well-known address obcan.sk and was perceived as
    one of the milestones in the construction of eGovernment in Slovakia. Its popularity at the time among the people
    was comparable to those of commercial serversy.
    </p>
    <p>
    During years 2006-2008 the functionality of the portal was extended; boxes, electronic signatures, forms and module
    for communication were added. A form for currently non-existent agenda of health insurance accounting submission was
    at the most successful one, with more than 800 000 monthly users. It arranged the integration of the Trade register
    with the Commercial register, Criminal record register with the information system of the Tax directorate, thus
    contributing to shortening the idle of receiving the Trade certificate from 30 days to only 5.
    </p>
    <p>
    In the year 2009 preparations for its second version began, meaning a significant functional and performance
    increase. Until the year 2004 it was extended by the authentication, guaranteed delivery, existing modules were
    migrated to new technologies with an extended functionality. At the same time, it was made available on a new
    address, slovensko.sk.
    </p>
    <p>
    The following years were dedicated to changes connected with the legislation, optimalization and performance
    tweaking for ever rising user load applied and an integration with information systems of the public administration.
    It has become a part of public administration, without which many processes would not function properly. 30 million
    messages securing various proceedings of the public administration in 2020 serve as an example.
    </p>
    <p>Happy birthday, slovensko.sk!</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      